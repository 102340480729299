import React, { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import DatePicker, { registerLocale } from "react-datepicker"
import { addDays } from "date-fns"
import "react-datepicker/dist/react-datepicker.css"
import nl from "date-fns/locale/nl"
import ArrowDown from "./arrow-down"

registerLocale("nl", nl)

function DateTime({
  name,
  type,
  value,
  touched,
  errors,
  placeholder,
  handleChange,
  setFieldValue,
  setFieldTouched,
}) {
  const ref = useRef(null)
  const inputClass = classNames({
    "form-control": true,
    "is-valid": touched && !errors,
    "is-invalid": touched && errors,
  })

  const icon = false

  // eslint-disable-next-line react/display-name
  const DatepickerInput = React.forwardRef((props, inputRef) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <input type="text" {...props} ref={inputRef} readOnly />
  ))

  const input = (
    <DatePicker
      ref={ref}
      name={name}
      id={name}
      placeholderText={placeholder}
      className={inputClass}
      selected={(value && new Date(value)) || null}
      dateFormat="dd-MM-yyyy"
      minDate={new Date()}
      maxDate={addDays(new Date(), 90)}
      readOnly={false}
      locale="nl"
      customInput={<DatepickerInput />}
      onChange={(val, event) => {
        handleChange(event, {
          name,
          value: val,
        })
        setFieldValue(name, val)
        setTimeout(() => {
          setFieldTouched(name, true)
        }, 5)
      }}
      autoComplete="off"
    />
  )

  if (icon) {
    const handleKeyDown = ev => {
      // check keys if you want
      if (ev.keyCode === 13) {
        ref.current.setOpen(true)
      }
    }
    return (
      <div className="input-group">
        {input}
        <div className="input-group-append">
          <span
            role="button"
            tabIndex={0}
            className="input-group-text"
            onKeyDown={handleKeyDown}
            onClick={() => {
              ref.current.setOpen(true)
            }}
          >
            <ArrowDown />
          </span>
        </div>
        {!touched && !errors && (
          <div className="invalid-feedback">{errors}</div>
        )}
      </div>
    )
  }

  return input
}

DateTime.defaultProps = {
  name: null,
}

DateTime.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default DateTime
