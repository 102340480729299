import React from "react"
import { Router } from "@reach/router"

import SEO from "../components/seo"
import Offer from "../components/offer"
import Success from "../components/success"
import Failed from "../components/failed"

import Checked from "../components/checked"
import Hero from "../components/hero"
import HeroImage from "../components/offerImage"

const usps = [
  "Binnen 24 uur een verhuis offerte",
  "Verhuisservice voor elk budget",
  "Verhuizen 24/7",
]
const Page = () => {
  return (
    <>
      <SEO title="Aanvraag verhuis offerte" />
      <Hero img={<HeroImage></HeroImage>}>
        <h1 className="font-weight-ligt">Aanvraag</h1>
        <h1 className="font-weight-bold">Verhuis offerte</h1>
      </Hero>

      <section className="section--sm bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ul className="list list--col-3">
                {usps.map((usp, i) => (
                  <li key={usp} className="mb-1">
                    <h5 className="font-weight-medium">
                      <Checked className="orange">{usp}</Checked>
                    </h5>
                  </li>
                ))}
              </ul>
              <p className="mt-1">
                Vraag een geheel vrijblijvend offerte aan. Voor een voordelig en
                professioneel verhuizing.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section--sm bg-offwhite">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mb-3">
              <Router basepath="/offerte">
                <Success path="bedankt" />
                <Failed path="helaas" />
                <Offer path="/:pathName" />
                <Offer path="/" />
              </Router>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Page
