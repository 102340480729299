import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

import { connect } from "react-redux"
import * as actionTypes from "../store/actions"

function Numeric({
  name,
  value,
  min,
  max,
  handleChange,
  handleBlur,
  setFieldValue,
  onObjectAdded,
}) {
  const inputClass = classNames({
    "form-control text-center": true,
  })

  const updateCount = (event, update) => {
    event.preventDefault()
    const newValue = (value || 0) + update
    if (newValue <= max && newValue >= min) {
      setFieldValue(name, newValue)
      onObjectAdded(newValue)
      handleChange(event, {
        name,
        value: newValue,
      })
    }
  }
  return (
    <div className="input-group input-group-sm  input-group--numeric">
      <span className="input-group-prepend">
        <button
          type="button"
          className="btn btn-numeric"
          onClick={event => updateCount(event, -1)}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </span>
      <input
        name={name}
        id={name}
        type="number"
        min={min}
        max={max}
        className={inputClass}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value || 0}
      />
      <span className="input-group-append">
        <button
          type="button"
          className="btn btn-numeric"
          onClick={event => updateCount(event, 1)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </span>
    </div>
  )
}

Numeric.defaultProps = {
  min: 0,
  max: 25,
  name: null,
}

Numeric.propTypes = {
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onObjectAdded: count =>
      dispatch({
        type: actionTypes.ADD_OBJECT,
        object: { ...props.object, count },
      }),
  }
}

export default connect(null, mapDispatchToProps)(Numeric)
