// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4924 0.723083C14.9486 0.723083 14.5078 1.16391 14.5078 1.7077V14.5077H1.70776C1.16397 14.5077 0.723145 14.9485 0.723145 15.4923C0.723145 16.0361 1.16397 16.4769 1.70776 16.4769H14.5078V28.2923C14.5078 28.8361 14.9486 29.2769 15.4924 29.2769C16.0362 29.2769 16.477 28.8361 16.477 28.2923V16.4769H28.2924C28.8362 16.4769 29.277 16.0361 29.277 15.4923C29.277 14.9485 28.8362 14.5077 28.2924 14.5077H16.477V1.7077C16.477 1.16391 16.0362 0.723083 15.4924 0.723083Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
