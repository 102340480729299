import React from "react"
import { get } from "lodash"

import FormGroup from "./formGroup"
import Input from "./input"

function ContactForm({
  prefix,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) {
  return (
    <>
      <FormGroup name={`${prefix}.email`} label="E-mail">
        <Input
          type="text"
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={get(errors, `${prefix}.email`)}
          touched={get(touched, `${prefix}.email`)}
          value={get(values, `${prefix}.email`)}
          autocomplete="email"
        ></Input>
      </FormGroup>
      <FormGroup name={`${prefix}.firstname`} label="Voornaam">
        <Input
          type="text"
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={get(errors, `${prefix}.firstname`)}
          touched={get(touched, `${prefix}.firstname`)}
          value={get(values, `${prefix}.firstname`)}
          autocomplete="given-name"
        ></Input>
      </FormGroup>
      <FormGroup name={`${prefix}.phonenumber`} label="Telefoonnummer">
        <Input
          type="text"
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={get(errors, `${prefix}.phonenumber`)}
          touched={get(touched, `${prefix}.phonenumber`)}
          value={get(values, `${prefix}.phonenumber`)}
          autocomplete="tel"
        ></Input>
      </FormGroup>
    </>
  )
}
ContactForm.propTypes = {}

ContactForm.defaultProps = {}

export default ContactForm
