// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76628 22.1733C8.76628 22.1733 17.8492 7.36901 30.58 0.816772C30.58 0.816772 14.2997 17.0059 11.1689 26.8933C9.51205 28.1064 6.7506 29.9959 6.7506 29.9959L6.59582 29.5128C5.89226 27.3657 2.89161 18.695 0 18.0831C0 18.0831 5.69175 11.0978 8.76628 22.1733Z"
        fill="#EE751F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55522 22.5204C7.28733 24.7951 6.66276 27.3824 6.7506 29.9958L6.59582 29.5128C5.89226 27.3656 2.89161 18.6949 0 18.083C0 18.083 5.69175 11.0978 8.76628 22.1732L8.55522 22.5204Z"
        fill="#EE751F"
      />
    </svg>
  )
}

export default Icon
