import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { get } from "lodash"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"

import { connect } from "react-redux"
import { m3ByCategory } from "../store/selectors"

import Numeric from "./numeric"
import FormGroup from "./formGroup"
import Collapse from "./collapse"
import Check from "./check-verhuisdeal"

const StyledCollapse = styled(Collapse)`
  color: #000;
  .card-header {
    background-color: transparent;
  }
`

const StyledCheck = styled(Check)`
  margin-bottom: 2px;
`

function Room(props) {
  const {
    id,
    prefix,
    name,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    objects,
    m3,
    open,
  } = props

  return (
    <>
      <StyledCollapse
        className="card--household"
        id={id}
        open={open}
        header={
          <h5 className="m-0 font-weight-normal">
            {name}
            <span className="float-right">
              <span className="ml-1">
                <FontAwesomeIcon icon={faChevronUp} />
              </span>
            </span>
          </h5>
        }
      >
        <div className="row">
          <div className="col-12 mb-6">
            <h5 className="font-weight-normal d-sm-flex">
              <span className="room__volume-label">
                Geschat verhuisvolume:&nbsp;
              </span>
              <span className="room__volume">
                <span className="font-weight-normal">{m3.toFixed(1)}</span>
                <span className="font-weight-light">
                  &nbsp;m
                  <sup>3</sup>
                </span>
              </span>
            </h5>
          </div>
        </div>

        {objects.map(object => {
          const copy = { ...object }
          copy.categoryName = id
          copy.categoryTitle = name
          copy.count = 0

          const formName = `${prefix}.${copy.categoryName}.${copy.objectName}`
          const value = get(values, `${formName}`)
          return (
            <div className="row" key={`${formName}`}>
              <div className="col-2 col-sm-1">
                {value > 0 && <StyledCheck />}
              </div>
              <div className="col-10 col-sm-11">
                <FormGroup
                  name={formName}
                  label={copy.objectTitle}
                  fixed="numeric"
                  className=""
                >
                  <Numeric
                    object={copy}
                    name={formName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    errors={get(errors, `${formName}`)}
                    touched={get(touched, `${formName}`)}
                    value={value}
                    volume={copy.m3}
                  ></Numeric>
                </FormGroup>
              </div>
            </div>
          )
        })}
      </StyledCollapse>
    </>
  )
}

Room.defaultProps = {
  open: false,
}

Room.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
  return {
    m3: m3ByCategory(state, props.id),
  }
}

export default connect(mapStateToProps)(Room)
