import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

function Radio({
  name,
  options,
  value,
  touched,
  errors,
  handleChange,
  handleBlur,
}) {
  const radioClass = classNames({
    "custom-control-input": true,
    "is-valid": touched && !errors,
    "is-invalid": touched && errors,
  })

  return (
    <>
      {Object.entries(options).map(([key, v]) => (
        <div key={key} className="h5 custom-control custom-radio">
          <input
            type="radio"
            id={`${name}${key}`}
            name={name}
            className={radioClass}
            onChange={handleChange}
            onBlur={handleBlur}
            value={key}
            checked={key === value}
          />
          <label className="custom-control-label" htmlFor={`${name}${key}`}>
            {v}
          </label>
        </div>
      ))}
    </>
  )
}

Radio.defaultProps = {
  name: null,
}

Radio.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object.isRequired,
}

export default Radio
