import { useSpring, config } from "react-spring"

/**
 * use like let { scrollTo } = useScrollToElement()
 * let elemRef = useRef()
 * scrollTo(elemRef.current)
 */
let isStopped = false

export default function useScrollToElement() {
  const [, setScroll] = useSpring(() => ({
    immediate: false,
    y: typeof window !== "undefined" ? window.pageYOffset : 0,
    // x: typeof window !== "undefined" ? window.pageXOffset : 0,
    onFrame: ({ x, y }) => {
      if (!isStopped) {
        window.scrollTo(x, y)
      }
    },
    onRest: () => {
      isStopped = false
      // window.removeEventListener("wheel", stopScrollOnWheel)
    },
    config: config.slow,
  }))
  const scrollTo = (node, offset) => {
    if (!window) {
      return
    }
    const rect = node.getBoundingClientRect()
    // window.addEventListener("wheel", stopScrollOnWheel)
    const y = rect.top + window.pageYOffset
    // const y = node.offsetTop + offset
    setScroll({
      y,
      // Also added support for vertical, shouldn't introduce any problem if your
      // page isn't wider than 100vw, but could reflect some unexpected behaviour
      // if you do, and want to scroll to element where element.left doesn't
      // intersect with the viewport's left edge.
      x: rect.left + window.pageXOffset,
    })
  }

  /*
  function stopScrollOnWheel() {
    window.removeEventListener("wheel", stopScrollOnWheel)
    isStopped = true
    stopScroll()
  }
  */

  return { scrollTo }
}
