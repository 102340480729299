import React, { Children, cloneElement } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import Label from "./label"

function FormGroup({ name, label, children, fixed }) {
  const renderChildren = () =>
    Children.map(children, child => {
      return cloneElement(child, {
        name,
      })
    })

  const labelClass = classNames({
    "col-sm-4": true,
    "label-sm": fixed,
  })

  const inputClass = classNames({
    "col-sm-8": !fixed,
    "col-sm-5 col-md-4 col-lg-3": fixed,
  })

  return (
    <div className="form-group row">
      <div className={labelClass}>
        <Label name={name}>{label}</Label>
      </div>
      <div className={inputClass}>{renderChildren()}</div>
    </div>
  )
}
FormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
}

FormGroup.defaultProps = {}

export default FormGroup
