import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCoffee } from "@fortawesome/free-solid-svg-icons"

function Input({
  name,
  type,
  autocomplete,
  icon,
  value,
  touched,
  errors,
  placeholder,
  handleChange,
  handleBlur,
}) {
  const inputClass = classNames({
    "form-control": true,
    "is-valid": touched && !errors,
    "is-invalid": touched && errors,
  })

  const input = (
    <input
      name={name}
      id={name}
      type={type}
      placeholder={placeholder}
      className={inputClass}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value || ""}
      autoComplete={autocomplete}
    />
  )
  if (icon) {
    return (
      <div className="input-group">
        {input}
        <div className="input-group-append">
          <span className="input-group-text">
            <FontAwesomeIcon icon={faCoffee} />
          </span>
        </div>
        {!touched && !errors && (
          <div className="invalid-feedback">{errors}</div>
        )}
      </div>
    )
  }
  return input
}

Input.defaultProps = {
  name: null,
}

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default Input
