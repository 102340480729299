import React from "react"
import { get } from "lodash"

import FormGroup from "./formGroup"
import Select from "./select"
import DateTime from "./datetime"

function MoveForm(props) {
  const {
    prefix,
    buttons,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = props

  return (
    <>
      {/* 
        <FormGroup
          name="startCertainty"
          label=" Weet u wanneer u gaat verhuizen?"
        >
          <Select
            name="startCertainty"
            options={{
              certain: "Ja",
              flexible: "Ja, en ik ben flexibel",
              estimate: "Nee, maar ik kan een inschatting geven",
            }}
                  ></Select>
        </FormGroup>
        */}
      <FormGroup name={`${prefix}.startDate`} label="Gewenste verhuisdatum">
        <DateTime
          type="text"
          setFieldTouched={setFieldTouched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          errors={get(errors, `${prefix}.startDate`)}
          touched={get(touched, `${prefix}.startDate`)}
          value={get(values, `${prefix}.startDate`)}
        ></DateTime>
      </FormGroup>
      <FormGroup
        name={`${prefix}.startTime`}
        label="Hoe laat wil je dat we beginnen?"
      >
        <Select
          options={{
            "": "Kies een tijd",
            "09:00": "09:00",
            "09:30": "09:30",
            "10:00": "10:00",
            "10:30": "10:30",
            "11:00": "11:00",
            "11:30": "11:30",
            "12:00": "12:00",
            "12:30": "12:30",
            "13:00": "13:00",
            "13:30": "13:30",
            "14:00": "14:00",
            "14:30": "14:30",
            "15:00": "15:00",
            "15:30": "15:30",
            "16:00": "16:00",
            "16:30": "16:30",
            "17:00": "17:00",
            "17:30": "17:30",
            "18:00": "18:00",
            "18:30": "18:30",
            "19:00": "19:00",
            "19:30": "19:30",
          }}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={get(errors, `${prefix}.startTime`)}
          touched={get(touched, `${prefix}.startTime`)}
          value={get(values, `${prefix}.startTime`)}
        ></Select>
      </FormGroup>

      <div className="form-group row">
        <div className="col-md-4"></div>
        <div className="col-sm-8">
          <div className="alert alert-danger d-none">
            <h5 className="alert-heading">Datum is in het verleden</h5>
            <p className="mb-0">
              Deze datum is al geweest, selecteer a.u.b. een datum in de
              toekomst.
            </p>
          </div>
          <div className="alert alert-warning d-none">
            <h5 className="alert-heading">Spoedverhuizing</h5>
            <p className="mb-0">
              De datum die u heeft geselecteerd is op zeer korte termijn, wij
              zullen zo snel mogelijk contact met u opnemen.
            </p>
          </div>
          <div className="alert alert-warning d-none">
            <h5 className="alert-heading">Datum ver in de toekomst</h5>
            <p className="mb-0">
              De datum die u heeft geselecteerd is ver in de toekomst.
            </p>
          </div>
        </div>
      </div>
      {buttons}
    </>
  )
}
MoveForm.propTypes = {}

MoveForm.defaultProps = {}

export default MoveForm
