// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
      <path
        d="M23.0003 9.93492C26.765 9.93492 29.8169 12.9868 29.8169 16.7515C29.8169 19.1067 28.6224 21.1829 26.8063 22.4074C31.5282 23.7973 34.9293 27.7046 34.9293 32.3073C34.9293 38.0751 11.0713 38.0751 11.0713 32.3073C11.0713 27.7046 14.4724 23.7973 19.1945 22.4067C17.3781 21.1829 16.1837 19.1067 16.1837 16.7515C16.1837 12.9868 19.2356 9.93492 23.0003 9.93492ZM23.0003 23.5681C17.3066 23.5681 12.7754 27.5349 12.7754 32.3073C12.7754 32.7113 13.5605 33.3619 15.3219 33.9297C17.3101 34.5706 20.0898 34.929 23.0003 34.929C25.9108 34.929 28.6904 34.5706 30.6786 33.9297C32.4401 33.3619 33.2251 32.7113 33.2251 32.3073C33.2251 27.5349 28.694 23.5681 23.0003 23.5681ZM23.0003 11.6391C20.1768 11.6391 17.8879 13.928 17.8879 16.7515C17.8879 19.575 20.1768 21.8639 23.0003 21.8639C25.8238 21.8639 28.1127 19.575 28.1127 16.7515C28.1127 13.928 25.8238 11.6391 23.0003 11.6391Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
