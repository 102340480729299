import React from "react"
import { get } from "lodash"

import FormGroup from "./formGroup"
import Input from "./input"
import Textarea from "./textarea"
import Select from "./select"
import Radio from "./radio"
import Checkbox from "./checkbox"

function MoreForm(props) {
  const {
    prefix,
    buttons,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = props

  return (
    <>
      <FormGroup
        name={`${prefix}.surface`}
        label="Vierkante meter van uw huidige woning"
      >
        <Input
          type="text"
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={get(errors, `${prefix}.surface`)}
          touched={get(touched, `${prefix}.surface`)}
          value={get(values, `${prefix}.surface`)}
        ></Input>
      </FormGroup>
      <FormGroup
        name={`${prefix}.helpers`}
        label="Helpt u met verhuizing en zo ja met hoeveel"
      >
        <Select
          options={{
            "": "Kies het aantal helpers",
            "0 helpers": "0 helpers",
            "1 helper": "1 helper",
            "2 helpers": "2 helpers",
            "3 helpers": "3 helpers",
            "4 helpers": "4 helpers",
            "5 helpers": "5 helpers",
            "6 helpers": "6 helpers",
            "7 helpers": "7 helpers",
            "8 helpers": "8 helpers",
          }}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={get(errors, `${prefix}.helpers`)}
          touched={get(touched, `${prefix}.helpers`)}
          value={get(values, `${prefix}.helpers`)}
        ></Select>
      </FormGroup>
        
      <FormGroup
        name={`${prefix}.lift`}
        label="Wilt u gebruik maken van een verhuislift?">
        <div className="custom-control-wrapper">
          <Radio
            name={`${prefix}.lift`}
            options={{
              Ja: "Ja, ik wil gebruik maken van een verhuislift",
              Nee: "Nee, ik wil geen gebruik maken van een verhuislift",
            }}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.lift`)}
            touched={get(touched, `${prefix}.lift`)}
            value={get(values, `${prefix}.lift`)}
          ></Radio>
        </div>
      </FormGroup>
      <FormGroup
        name={`${prefix}.montage`}
        label="Wilt u gebruik maken van de gratis Montage service incl. gereedschap?"
      >
        <div className="custom-control-wrapper">
          <Radio
            name={`${prefix}.montage`}
            options={{
              Ja: "Ja, ik wil gebruik maken van de gratis montage service.",
              Nee: "Nee, ik doe zelf demontage en montage",
            }}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.montage`)}
            touched={get(touched, `${prefix}.montage`)}
            value={get(values, `${prefix}.montage`)}
          ></Radio>
        </div>
      </FormGroup>
      <FormGroup name={`${prefix}.comment`} label="Vragen of opmerkingen">
        <Textarea
          type="text"
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={get(errors, `${prefix}.comment`)}
          touched={get(touched, `${prefix}.comment`)}
          value={get(values, `${prefix}.comment`)}
        ></Textarea>
      </FormGroup>
      <FormGroup name={`${prefix}.terms`} label="Algemene voorwaarden">
        <div className="custom-control-wrapper">
          <Checkbox
            name={`${prefix}.terms`}
            options={{
              ok: "Ik ga akkoord met de algemene voorwaarden",
            }}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.terms`)}
            touched={get(touched, `${prefix}.terms`)}
            value={get(values, `${prefix}.terms`)}
          ></Checkbox>
        </div>
      </FormGroup>

      {buttons}
    </>
  )
}
MoreForm.propTypes = {}

MoreForm.defaultProps = {}

export default MoreForm
