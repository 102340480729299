import React from "react"
import { useTransition, useSpring, animated } from "react-spring"
import { connect } from "react-redux"
import { m3 } from "../store/selectors"

const StickyM3 = ({ surfaces, isVisible = true }) => {
  const total = surfaces && surfaces.total ? surfaces.total : 0
  const spring = useSpring({ from: { val: 0 }, to: { val: total } })

  const transitions = useTransition(isVisible, null, {
    from: {
      transform: "translate3d(0, 100%,0)",
    },
    enter: {
      transform: "translate3d(0,0px,0)",
    },
    leave: {
      transform: "translate3d(0,100%,0)",
    },
  })

  if (!isVisible) return null

  return (
    <>
      {transitions.map(
        ({ item, props, key }) =>
          item && (
            <animated.div
              className="bg-white fixed-bottom py-3 px-md-3"
              style={{
                ...props,
              }}
              key={key}
            >
              <div className="bg-white fixed-bottom py-3 px-md-3">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h5 className="font-weight-normal">
                        <span className="">
                          Geschat totaal verhuisvolume:&nbsp;
                        </span>
                        <span className="">
                          <span className="font-weight-normal">
                            <animated.span>
                              {spring.val.interpolate(val => val.toFixed(1))}
                            </animated.span>
                          </span>
                          <span className="font-weight-light">
                            &nbsp;m
                            <sup>3</sup>
                          </span>
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          )
      )}
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    surfaces: m3(state),
  }
}

export default connect(mapStateToProps)(StickyM3)
