import React from "react"
import PropTypes from "prop-types"

import styled from "@emotion/styled"
import Checked from "./checked"
import Pricing from "./pricing"

const StyledPricing = styled(Pricing)`
  position: absolute;
  bottom: -120px;
  z-index: 2;
  right: 16px;
`

const Wrapper = styled.figure`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

function Hero({ variant, usps, children, img }) {
  return (
    <>
      <section className="hero--small bg-orange-gradient-300">
        <Wrapper>{img}</Wrapper>
        <div className="container-fluid h-100 d-flex align-items-center">
          <div className="row mb-4">
            <div className="col-12">{children}</div>
          </div>
        </div>
      </section>
      {usps && (
        <section className="bg-orange-gradient-300 d-none d-md-block">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-12 col-md-7">
                <ul className="list usps-list--hero">
                  {usps.map((usp, i) => (
                    <li key={usp}>
                      <h5 className="font-weight-medium">
                        <Checked>{usp}</Checked>
                      </h5>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-12 col-md-5 offset-lg-1 col-lg-4 position-relative">
                <StyledPricing></StyledPricing>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

Hero.propTypes = {
  variant: PropTypes.bool,
}

Hero.defaultProps = {
  variant: true,
}

export default Hero
