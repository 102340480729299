import React, { useEffect, useLayoutEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"

import { Link, navigate } from "gatsby"
import * as Yup from "yup"
import { Formik } from "formik"
import { merge } from "lodash"
import styled from "@emotion/styled"
import classNames from "classnames/bind"

import { connect } from "react-redux"
import useScrollToElement from "../hooks/useScrollToElement"

import Collapse from "./collapse"
import usePrevious from "../hooks/usePrevious"
import SEO from "./seo"
import * as actionTypes from "../store/actions"
import Check from "./check-verhuisdeal"

import { ecommerceEvent, ecommerceValues } from "../utils/events"
import { m3 } from "../store/selectors"

import CalendarIcon from "./calendarIcon"
import PersonIcon from "./personIcon"
import AddressIcon from "./addressIcon"
import HouseholdIcon from "./householdIcon"
import MoreIcon from "./moreIcon"
import StickyM3 from "./stickyM3"

const StyledCollapse = styled(Collapse)`
  .card-header {
  }
`
const MultiStepForm = props => {
  const {
    children,
    onSubmit,
    onInputChange,
    initialValues,
    pathName,
    surfaces,
  } = props

  const pages = React.Children.toArray(children)
  const page = pages.findIndex(p => p.props.pathName === pathName)
  // const [page, setPage] = useState(i)
  const prevPage = usePrevious(page)
  const { scrollTo } = useScrollToElement()

  const progress = (page / (pages.length - 1)) * 100.0
  const currentPage = pages[page]
  const isLastPage = page === pages.length - 2
  const isFinalPage = page === pages.length - 1

  // const elementsRef = useRef(pages.map(() => createRef()))

  const pageRefs = useRef({})
  const validatedPages = useRef({})

  const isPageValidated = useCallback(
    currentIndex => {
      const val = pages.reduce((acc, p, index) => {
        return (
          acc &&
          (currentIndex < index || validatedPages.current[p.props.prefix])
        )
      }, true)
      return val
    },
    [pages]
  )

  useEffect(() => {
    if (pathName !== "" && page === -1) {
      navigate(`/offerte`)
    }
    const isValidated = isPageValidated(page - 1)
    if (!isValidated) {
      navigate(`/offerte`)
    }
  }, [isPageValidated, page, pathName])

  useLayoutEffect(() => {
    if (page < 0 || page >= pages.length || page === prevPage) return
    const to =
      prevPage !== undefined && prevPage >= 0 && prevPage < page
        ? pages[prevPage]
        : pages[page]
    scrollTo(pageRefs.current[to.props.prefix], 0)
  }, [page, pages, pathName, prevPage, scrollTo])

  const mergeInitialValues = () => {
    const initial = pages.reduce((acc, val) => {
      const v = {
        [val.props.prefix]: { ...val.props.initialValues },
      }
      return { ...acc, ...v }
    }, {})
    const merged = merge(initial, initialValues)
    return merged
  }

  const navigateToPage = nextPage => {
    if (nextPage !== page) {
      const to = pages[nextPage]
      navigate(`/offerte/${to.props.pathName}`)
    }
  }

  const start = event => {}

  const previous = () => {
    const nextPage = Math.max(page - 1, 0)
    validatedPages.current = {
      ...validatedPages.current,
      [currentPage.props.prefix]: false,
    }

    navigateToPage(nextPage)
  }

  const next = () => {
    const nextPage = Math.min(page + 1, pages.length - 1)
    validatedPages.current = {
      ...validatedPages.current,
      [currentPage.props.prefix]: true,
    }
    navigateToPage(nextPage)
  }

  const handleSubmit = async values => {
    const transactionProducts = ecommerceValues(values.household)
    const submitValues = {
      ...values,
      surface: { ...surfaces },
      rooms: { ...transactionProducts },
    }
    const status = await onSubmit(submitValues)
    ecommerceEvent(
      status.data && status.data.id ? status.data.id : 0,
      transactionProducts
    )
    const path =
      status.data && status.data.success
        ? `/offerte/bedankt`
        : `/offerte/helaas`
    navigate(path, { state: { values } })
  }

  const handleSubmits = async (values, { setSubmitting, setTouched }) => {
    setTouched({})
    if (currentPage.props.onPageSubmit) {
      currentPage.props.onPageSubmit()
    }

    if (isFinalPage) {
      await handleSubmit(values)
      setSubmitting(false)
    } else {
      setSubmitting(false)
      next()
    }
  }

  const onOpen = (nextPage, isOpen) => {
    if (isOpen) {
      return false
    }
    if (isPageValidated(nextPage - 1)) {
      navigateToPage(nextPage)
      return true
    }
    return false
  }

  const buttonLabel = () => {
    if (isFinalPage) {
      return "Vraag offerte aan"
    }
    if (isLastPage) {
      return "Laatste stap"
    }
    return "Volgende stap"
  }

  const title = () => {
    let t = "Vraag een offerte"
    if (!currentPage || currentPage === undefined) {
      t = "Vraag een offerte"
    } else {
      t = currentPage.props.subTitle
        ? `${currentPage.props.title} - ${currentPage.props.subTitle}`
        : currentPage.props.title
    }
    return t
  }

  return (
    <>
      <SEO title={`Offerte - ${title()}`}></SEO>
      {page === -1 && (
        <>
          <p>
            Op deze pagina kun je in 5 stappen een op maat gemaakte
            verhuisofferte aanvragen.
            <br />
            Wij zullen je aanvraag binnen 24 uur toesturen
          </p>
          <ul className="step__list mt-5 mb-1 mt-md-9 mb-md-10">
            <li>
              <div className="step">
                <div className="step__icon">
                  <CalendarIcon />
                </div>
                <div className="step__content">
                  <div className="step__stepcount">Stap 1</div>
                  <div className="step__title">Verhuisdatum</div>
                </div>
              </div>
            </li>
            <li>
              <div className="step">
                <div className="step__icon">
                  <PersonIcon />
                </div>
                <div className="step__content">
                  <div className="step__stepcount">Stap 2</div>
                  <div className="step__title">Persoonsgegevens</div>
                </div>
              </div>
            </li>
            <li>
              <div className="step">
                <div className="step__icon">
                  <AddressIcon />
                </div>
                <div className="step__content">
                  <div className="step__stepcount">Stap 3</div>
                  <div className="step__title">Verhuisadressen</div>
                </div>
              </div>
            </li>
            <li>
              <div className="step">
                <div className="step__icon">
                  <HouseholdIcon />
                </div>
                <div className="step__content">
                  <div className="step__stepcount">Stap 4</div>
                  <div className="step__title">Inboedel</div>
                </div>
              </div>
            </li>
            <li>
              <div className="step">
                <div className="step__icon">
                  <MoreIcon />
                </div>
                <div className="step__content">
                  <div className="step__stepcount">Stap 5</div>
                  <div className="step__title">Extra's</div>
                </div>
              </div>
            </li>
          </ul>
          <Link
            to="/offerte/start"
            className="btn btn-primary"
            onClick={event => start(event)}
          >
            Start offerte aanvraag
          </Link>
        </>
      )}
      <Formik
        initialValues={mergeInitialValues()}
        validationSchema={
          currentPage &&
          Yup.object().shape({
            [currentPage.props.prefix]: Yup.object().shape(
              currentPage.props.validationSchema
            ),
          })
        }
        onSubmit={(values, actions) => {
          handleSubmits(values, actions)
        }}
      >
        {formProps => {
          const myHandleChange = (event, obj) => {
            if (event && event.target && event.target.name) {
              formProps.handleChange(event)
            }
            const input = {
              name: event.target.name || (obj && obj.name),
              value: event.target.value || (obj && obj.value),
            }
            onInputChange(input)
          }
          return (
            <form onSubmit={formProps.handleSubmit} id="offerForm">
              <div role="tablist" className="" aria-multiselectable="true">
                {pages.map((child, index) => {
                  const cardClass = classNames({
                    "card--form": true,
                    "is-valid": index < page,
                    "is-active": index === page,
                  })
                  return (
                    <StyledCollapse
                      key={child.props.prefix}
                      id={child.props.prefix}
                      open={index === page}
                      handleOpen={isOpen => onOpen(index, isOpen)}
                      className={cardClass}
                      header={
                        <div className="d-flex align-items-center">
                          <div className="card-check mr-2">
                            {index < page && <Check />}
                            {index === page && <span>{index + 1}</span>}
                          </div>
                          <h5 className="card-title m-0 font-weight-bold">
                            {child.props.title}
                          </h5>
                          {index < page && (
                            <div className="ml-auto">wijzig</div>
                          )}
                        </div>
                      }
                      ref={ref => {
                        pageRefs.current = {
                          ...pageRefs.current,
                          [child.props.prefix]: ref,
                        }
                      }}
                    >
                      {React.cloneElement(child, {
                        ...formProps,
                        handleChange: myHandleChange,
                      })}
                    </StyledCollapse>
                  )
                })}
              </div>
              {page >= 0 && (
                <>
                  <div className="d-flex flex-column flex-md-row justify-content-between flex-md-row-reverse mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary mb-3 mb-md-0"
                      disabled={formProps.isSubmitting}
                    >
                      {buttonLabel()}
                    </button>
                    {page > 0 && (
                      <button
                        type="button"
                        className="link link-secondary"
                        onClick={previous}
                        disabled={formProps.isSubmitting}
                      >
                        Vorige stap
                      </button>
                    )}
                  </div>
                  <div className="progress my-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span className="d-none">`${progress}%`</span>
                    </div>
                  </div>
                </>
              )}
            </form>
          )
        }}
      </Formik>
      {page !== -1 && <StickyM3 isVisible={page >= 3}></StickyM3>}
    </>
  )
}

MultiStepForm.defaultProps = {
  pathName: "",
}

MultiStepForm.propTypes = {
  pathName: PropTypes.string,
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onInputChange: input =>
      dispatch({
        type: actionTypes.CHANGE_FORMINPUT,
        input: { ...input },
      }),
  }
}

const mapStateToProps = (state, props) => {
  return {
    surfaces: m3(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiStepForm)
