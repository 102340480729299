// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg viewBox="0 0 20 17" fill="none" className={className}>
      <path
        d="M2 7L8.78788 13.5L18 2.5"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Icon
