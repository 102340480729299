import React from "react"

const Failed = () => {
  return (
    <>
      <h4>Er is iets misgegaan met je aanvraag</h4>
      <p>Bel ons voor een afspraak.</p>
    </>
  )
}

Failed.propTypes = {}

Failed.defaultProps = {}

export default Failed
