import React from "react"
import { get } from "lodash"

import classNames from "classnames/bind"
import debounce from "lodash.debounce"
import FormGroup from "./formGroup"
import Input from "./input"
import Select from "./select"
import axios from "../utils/axios-postcodecheck"

const addresses = [
  {
    prefix: "from",
    title: "Van - naar verhuisadressen",
    subTitle: "Begindadres",
  },
  {
    prefix: "to",
    title: "Van - naar verhuisadressen",
    subTitle: "Eindadres",
  },
]

const dutchPostalcode = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i

// https://geodata.nationaalgeoregister.nl/locatieserver/free?fq:postcode:1018JV&fq=huisnummer~49
// https://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:1018JV&fq=huisnummer~49*

const checkPostcodeNederland = ({
  address,
  prefix,
  postalcode,
  streetnumber,
  setFieldValue,
}) => {
  axios
    .get(`/free?fq=postcode:${postalcode}+AND+huisnummer:${streetnumber}`)
    .then(
      response => {
        const json = response.data
        const numFound = json.response.numFound
        if (numFound === 0) {
          return
        }
        const doc = json.response.docs[0]
        setFieldValue(`${prefix}.${address.prefix}_city`, doc.woonplaatsnaam)
        setFieldValue(`${prefix}.${address.prefix}_streetname`, doc.straatnaam)
      },
      error => {
        console.log(
          "Something went wrong with the checkPostcodeNederland: ",
          error
        )
      }
    )
}

const debounceCheckPostcodeNederland = debounce(checkPostcodeNederland, 1000)

function AddressForm({
  prefix,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) {
  const handlePostcodeCheck = (
    e,
    { address, postalcode, country, streetnumber }
  ) => {
    handleChange(e)

    let countryValue =
      country || get(values, `${prefix}.${address.prefix}_country`)
    let postalcodeValue =
      postalcode || get(values, `${prefix}.${address.prefix}_postalcode`)
    let streetnumberValue =
      streetnumber || get(values, `${prefix}.${address.prefix}_streetnumber`)

    countryValue = countryValue.replace(/\s+/g, "")
    postalcodeValue = postalcodeValue.replace(/\s+/g, "")
    streetnumberValue = streetnumberValue.replace(/\s+/g, "")

    if (
      countryValue !== "Nederland" ||
      !postalcodeValue ||
      postalcodeValue === "" ||
      !dutchPostalcode.test(postalcodeValue) ||
      !streetnumberValue ||
      streetnumberValue === ""
    ) {
      return
    }
    // http://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:1018JV+AND+huisnummer:49

    debounceCheckPostcodeNederland({
      address,
      prefix,
      setFieldValue,
      postalcode: postalcodeValue,
      streetnumber: streetnumberValue,
    })
  }

  return addresses.map((address, i) => {
    const titleClass = classNames({
      "font-weight-normal mb-4 ": true,
      "mt-2 mt-md-4": i === 0,
      "mt-6 mt-md-10": i > 0,
    })

    return (
      <div key={address.prefix}>
        <h5 className={titleClass}>{address.subTitle}</h5>
        <FormGroup name={`${prefix}.${address.prefix}_country`} label="Land">
          <Select
            options={{
              "": "Kies een land",
              Nederland: "Nederland",
              België: "België",
              Luxemburg: "Luxemburg",
              Duitsland: "Duitsland",
              Denemarken: "Denemarken",
              "Verenigd Koninkrijk": "Verenigd Koninkrijk",
              Frankrijk: "Frankrijk",
              Spanje: "Spanje",
              Italië: "Italië",
            }}
            handleChange={e => {
              handlePostcodeCheck(e, { address, country: e.target.value })
            }}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_country`)}
            touched={get(touched, `${prefix}.${address.prefix}_country`)}
            value={get(values, `${prefix}.${address.prefix}_country`)}
            autocomplete="country"
          ></Select>
        </FormGroup>
        <FormGroup
          name={`${prefix}.${address.prefix}_postalcode`}
          label="Postcode"
        >
          <Input
            type="text"
            handleChange={e => {
              handlePostcodeCheck(e, { address, postalcode: e.target.value })
            }}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_postalcode`)}
            touched={get(touched, `${prefix}.${address.prefix}_postalcode`)}
            value={get(values, `${prefix}.${address.prefix}_postalcode`)}
            autocomplete="postal-code"
          ></Input>
        </FormGroup>
        <FormGroup
          name={`${prefix}.${address.prefix}_streetnumber`}
          label="Huisnummer"
        >
          <Input
            type="text"
            handleChange={e => {
              handlePostcodeCheck(e, { address, streetnumber: e.target.value })
            }}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_streetnumber`)}
            touched={get(touched, `${prefix}.${address.prefix}_streetnumber`)}
            value={get(values, `${prefix}.${address.prefix}_streetnumber`)}
            autocomplete="streetnumber"
          ></Input>
        </FormGroup>
        <FormGroup
          name={`${prefix}.${address.prefix}_addition`}
          label="Toevoeging"
        >
          <Input
            type="text"
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_addition`)}
            touched={get(touched, `${prefix}.${address.prefix}_addition`)}
            value={get(values, `${prefix}.${address.prefix}_addition`)}
          ></Input>
        </FormGroup>
        <FormGroup
          name={`${prefix}.${address.prefix}_streetname`}
          label="Straat"
        >
          <Input
            type="text"
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_streetname`)}
            touched={get(touched, `${prefix}.${address.prefix}_streetname`)}
            value={get(values, `${prefix}.${address.prefix}_streetname`)}
            autocomplete="street-address"
          ></Input>
        </FormGroup>
        <FormGroup name={`${prefix}.${address.prefix}_city`} label="Stad">
          <Input
            type="text"
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_city`)}
            touched={get(touched, `${prefix}.${address.prefix}_city`)}
            value={get(values, `${prefix}.${address.prefix}_city`)}
            autocomplete="city"
          ></Input>
        </FormGroup>
        <h5 className="font-weight-normal mt-4 mb-4">Verhuisinformatie</h5>
        <FormGroup
          name={`${prefix}.${address.prefix}_location`}
          label="Woonsituatie"
        >
          <Select
            options={{
              "": "Kies een woonsituatie",
              Woonhuis: "Woonhuis",
              Appartement: "Appartement",
              Studio: "Studio",
              Eengezinswoning: "Eengezinswoning",
              Villa: "Villa",
              Opslag: "Opslag",
              Ander: "Ander",
            }}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_location`)}
            touched={get(touched, `${prefix}.${address.prefix}_location`)}
            value={get(values, `${prefix}.${address.prefix}_location`)}
          ></Select>
        </FormGroup>
        <FormGroup
          name={`${prefix}.${address.prefix}_floor`}
          label="Verdieping"
        >
          <Select
            options={{
              "": "Kies een verdieping",
              Souterrain: "Souterrain",
              "Begane grond": "Begane grond",
              "1 hoog": "1 hoog",
              "2 hoog": "2 hoog",
              "3 hoog": "3 hoog",
              "4 hoog": "4 hoog",
              "5 hoog": "5 hoog",
              "6 hoog": "6 hoog",
              "7 hoog": "7 hoog",
              "8 hoog": "8 hoog",
              "9 hoog": "9 hoog",
              "10 hoog": "10 hoog",
              "11 hoog": "11 hoog",
            }}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_floor`)}
            touched={get(touched, `${prefix}.${address.prefix}_floor`)}
            value={get(values, `${prefix}.${address.prefix}_floor`)}
          ></Select>
        </FormGroup>
        <FormGroup
          name={`${prefix}.${address.prefix}_distance`}
          label="Afstand tot de deur"
        >
          <Select
            name="distance"
            options={{
              "": "Kies een afstand",
              "0 meter": "0 meter",
              "10 meter": "10 meter",
              "20 meter": "20 meter",
              "30 meter": "30 meter",
              "40 meter": "40 meter",
              "50 meter": "50 meter",
              "75 meter": "75 meter",
              "100 meter": "100 meter",
              "150 meter": "150 meter",
              "200 meter": "200 meter",
            }}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_distance`)}
            touched={get(touched, `${prefix}.${address.prefix}_distance`)}
            value={get(values, `${prefix}.${address.prefix}_distance`)}
          ></Select>
        </FormGroup>
        <FormGroup
          name={`${prefix}.${address.prefix}_elevator`}
          label="Is er een lift aanwezig?"
        >
          <Select
            options={{
              "": "Kies een optie",
              Ja: "Ja, er is een lift aanwezig",
              Nee: "Nee, er is geen lift aanwezig",
            }}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={get(errors, `${prefix}.${address.prefix}_elevator`)}
            touched={get(touched, `${prefix}.${address.prefix}_elevator`)}
            value={get(values, `${prefix}.${address.prefix}_elevator`)}
          ></Select>
        </FormGroup>
      </div>
    )
  })
}
AddressForm.propTypes = {}

AddressForm.defaultProps = {}

export default AddressForm
