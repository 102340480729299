import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useSpring, animated } from "react-spring"
import useComponentSize from "@rehooks/component-size"
import styled from "@emotion/styled"
import classNames from "classnames/bind"
import usePrevious from "../hooks/usePrevious"

const Header = styled.button`
  text-align: left;
`

const Collapse = React.forwardRef((props, cardRef) => {
  const { id, header, children, open, className, handleOpen } = props
  const [isOpen, setOpen] = useState(open)
  const previous = usePrevious(isOpen)
  const ref = useRef(null)
  const { height: viewHeight } = useComponentSize(ref)
  const { height } = useSpring({
    from: { height: 0 },
    to: {
      height: isOpen ? viewHeight : 0,
    },
    // delay: 400,
  })

  useEffect(() => {
    setOpen(open)
    return () => {}
  }, [open])

  const cardClass = classNames({
    card: true,
    [className]: className,
  })

  const headerClass = classNames({
    "card-header": true,
    collapsed: !isOpen,
  })

  const panelClass = classNames({
    panel: true,
    collapse: true,
    show: true,
  })

  const toggleOpen = () => {
    if (handleOpen) {
      handleOpen(isOpen)
    } else {
      setOpen(!isOpen)
    }
    //
  }

  return (
    <div className={cardClass} ref={cardRef} id={`${id}-card`}>
      <Header
        role="tab"
        className={headerClass}
        id={`${id}-header`}
        aria-expanded={isOpen}
        aria-controls={`${id}-panel`}
        onClick={event => {
          event.preventDefault()
          toggleOpen()
        }}
      >
        {header}
      </Header>

      <animated.div
        role="tabpanel"
        id={`${id}-panel`}
        aria-labelledby={`${id}-header`}
        className={panelClass}
        // eslint-disable-next-line no-nested-ternary
        style={{ height: previous !== isOpen ? height : isOpen ? "auto" : 0 }}
      >
        <div ref={ref} className="card-body">
          {children}
        </div>
      </animated.div>
    </div>
  )
})

Collapse.defaultProps = {
  open: false,
  className: "",
  id: "collapse",
}

Collapse.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
}

export default Collapse
