import React, { useEffect } from "react"
import * as Yup from "yup"

import { connect } from "react-redux"
import MoveForm from "./moveForm"
import ContactForm from "./contactForm"
import AddressForm from "./addressForm"
import HouseHoldForm from "./houseHoldForm"
import MoreForm from "./moreForm"
import MultiStepForm from "./multiStepForm"
import axios from "../utils/axios-orders"
import { fetchInitialValues } from "../store/selectors"
import * as actionTypes from "../store/actions"

const phoneRegExp = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/

const Offer = ({
  pathName,
  onFetchInitialValues,
  onRequestOffer,
  initialValues,
}) => {
  useEffect(() => {
    onFetchInitialValues()
  }, [onFetchInitialValues])

  const onSubmit = async (values, actions) => {
    try {
      const response = await axios.post("/mail", values)
      return response
    } catch (error) {
      console.log("Something went wrong submitting the form: ", error)
      return false
    }
  }

  return (
    <>
      <MultiStepForm
        onSubmit={onSubmit}
        pathName={pathName}
        initialValues={initialValues}
      >
        <MoveForm
          prefix="datetime"
          pathName="start"
          title="Wanneer wil je verhuizen?"
          initialValues={{
            startDate: "",
            startTime: "",
          }}
          validationSchema={{
            startDate: Yup.string().required("Gewenste verhuisdatum verplicht"),
            startTime: Yup.string().required("Gewenste starttijd is verplicht"),
          }}
        />
        <ContactForm
          prefix="contact"
          pathName="contact"
          title="Je contactgegevens"
          initialValues={{
            firstname: "",
            email: "",
            phonenumber: "",
          }}
          validationSchema={{
            firstname: Yup.string().required("Voornaam is verplicht"),
            email: Yup.string()
              .email()
              .required("E-mail is verplicht"),

            phonenumber: Yup.string()
              .required("Telefoonnummer is verplicht")
              .matches(phoneRegExp, "Telefoonnummer is verplicht"),
          }}
        />
        <AddressForm
          key="address"
          prefix="address"
          pathName="adres"
          title="Van - naar verhuisadressen"
          initialValues={{
            from_country: "",
            from_postalcode: "",
            from_streetnumber: "",
            from_addition: "",
            from_streetname: "",
            from_city: "",
            from_location: "",
            from_floor: "",
            from_distance: "",
            from_elevator: "",
            to_country: "",
            to_postalcode: "",
            to_streetnumber: "",
            to_addition: "",
            to_streetname: "",
            to_city: "",
            to_location: "",
            to_floor: "",
            to_distance: "",
            to_elevator: "",
          }}
          validationSchema={{
            from_country: Yup.string().required("Land is verplicht"),
            from_postalcode: Yup.string().required("Postcode is verplicht"),
            from_streetnumber: Yup.string().required("Huisnummer is verplicht"),
            from_streetname: Yup.string().required("Straatnaam is verplicht"),
            from_city: Yup.string().required("Stad is verplicht"),
            from_location: Yup.string().required("Woonsituatie is verplicht"),
            from_floor: Yup.string().required("Verdieping is verplicht"),
            from_distance: Yup.string().required("Afstand is verplicht"),
            from_elevator: Yup.string().required("Lift is verplicht"),
            to_country: Yup.string().required("Land is verplicht"),
            to_postalcode: Yup.string().required("Postcode is verplicht"),
            to_streetnumber: Yup.string().required("Huisnummer is verplicht"),
            to_streetname: Yup.string().required("Straatnaam is verplicht"),
            to_city: Yup.string().required("Stad is verplicht"),
            to_location: Yup.string().required("Woonsituatie is verplicht"),
            to_floor: Yup.string().required("Verdieping is verplicht"),
            to_distance: Yup.string().required("Afstand is verplicht"),
            to_elevator: Yup.string().required("Lift is verplicht"),
          }}
        />
        <HouseHoldForm
          prefix="household"
          pathName="inboedel"
          title="Uw inboedel"
        />
        <MoreForm
          prefix="more"
          pathName="laatste"
          title="Als laatste"
          initialValues={{
            surface: "",
            helpers: "0",
            lift: "",
            montage: "",
            terms: "",
          }}
          validationSchema={{
            surface: Yup.string().required("Vierkante meter is verplicht"),
            helpers: Yup.string().required(
              "Vul in met hoeveel mensen u meehehelpt"
            ),
            montage: Yup.string().required("Montage is verplicht"),
            lift: Yup.string().required("Verhuislift is verplicht"),
            terms: Yup.string().required(
              "U moet nog akkoord gaan met de voorwaarden"
            ),
          }}
        />
      </MultiStepForm>
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: fetchInitialValues(state),
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onRequestOffer: orderData => dispatch(actionTypes.requestOffer(orderData)),
    onFetchInitialValues: input =>
      dispatch({
        type: actionTypes.FETCH_INITIAL_VALUES,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offer)
