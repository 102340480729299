import React, { useLayoutEffect } from "react"
import { Link } from "gatsby"

const Success = props => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <h5 className="font-weight-bold mb-1">Bedankt voor de aanvraag</h5>
      <p className="mb-3">We bellen je zo snel mogelijk terug.</p>
      <Link to="/" className="btn btn-primary">
        Terug naar Home
      </Link>
    </>
  )
}

Success.propTypes = {}

Success.defaultProps = {}

export default Success
