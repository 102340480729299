import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

function Select({
  name,
  options,
  value,
  autocomplete,
  touched,
  errors,
  handleChange,
  handleBlur,
}) {
  const selectClass = classNames({
    "custom-select": true,
    "is-valid": touched && !errors,
    "is-invalid": touched && errors,
  })

  return (
    <select
      id={name}
      name={name}
      className={selectClass}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      autoComplete={autocomplete}
    >
      {Object.entries(options).map(([key, v]) => (
        <option key={key} value={key}>
          {v}
        </option>
      ))}
    </select>
  )
}
Select.defaultProps = {
  name: null,
}

Select.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object.isRequired,
}

export default Select
