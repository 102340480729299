// Icon
import React from "react"

function Icon({ className }) {
  return (
    <svg
      width="60"
      height="59"
      viewBox="0 0 60 59"
      fill="none"
      className={className}
    >
      <path
        d="M58.5685 28.2843L33.8198 53.033C31.8672 54.9856 28.7013 54.9856 26.7487 53.033L1.99997 28.2843"
        stroke="#EE751F"
        strokeWidth="3"
      />
    </svg>
  )
}

export default Icon
