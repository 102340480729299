import React from "react"
import { Link } from "gatsby"
import verhuiswagen from "../images/misc/verhuiswagen.svg"
import verhuizer from "../images/misc/verhuizer.svg"
import lift from "../images/misc/lift.svg"

function Pricing({ className }) {
  return (
    <div className={`price-box bg-white ${className}`}>
      <h3 className="price__title font-weight-medium mb-4">
        Onze lage verhuis tarieven
      </h3>
      <ul className="prices text-left">
        <li className="price">
          <div className="price__icon">
            <img src={verhuiswagen} alt="Verhuiswagen verhuisdeal24" />
          </div>
          <div className="price__description">
            <h5 className="font-weight-light">Verhuizer en verhuiswagen</h5>
          </div>
          <div className="price__price">
            <h5 className="font-weight-light">
              <b>€35</b> p.u.
            </h5>
          </div>
        </li>
        <li className="price">
          <div className="price__icon">
            <img src={verhuizer} alt="Verhuizer verhuisdeal24" />
          </div>
          <div className="price__description">
            <h5 className="font-weight-light">Verhuizer</h5>
          </div>
          <div className="price__price">
            <h5 className="font-weight-light">
              <b>€25</b> p.u.
            </h5>
          </div>
        </li>
        <li className="price">
          <div className="price__icon">
            <img src={lift} alt="Lift verhuisdeal24" />
          </div>
          <div className="price__description">
            <h5 className="font-weight-light">Verhuislift</h5>
          </div>
          <div className="price__price">
            <h5 className="font-weight-light">
              <b>€75</b> p.u.
            </h5>
          </div>
        </li>
      </ul>
      <div className="price__cta mt-4 text-center">
        <Link to="/tarieven" className="btn btn-primary">
          Meer over tarieven
        </Link>
      </div>
    </div>
  )
}

Pricing.propTypes = {}

Pricing.defaultProps = {}

export default Pricing
