import React, { Children } from "react"
import PropTypes from "prop-types"

function Label({ name, children }) {
  return (
    <label className="h5 col-form-label" htmlFor={name}>
      {Children.toArray(children)}
    </label>
  )
}

Label.defaultProps = {}

Label.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Label
