import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

function Textarea({
  name,
  type,
  value,
  touched,
  errors,
  placeholder,
  handleChange,
  handleBlur,
}) {
  const inputClass = classNames({
    "form-control": true,
    "is-valid": touched && !errors,
    "is-invalid": touched && errors,
  })

  return (
    <textarea
      name={name}
      id={name}
      type={type}
      rows={5}
      placeholder={placeholder}
      className={inputClass}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value || ""}
    />
  )
}

Textarea.defaultProps = {
  name: null,
}

Textarea.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default Textarea
