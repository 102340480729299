import React from "react"
import styled from "@emotion/styled"
import Check from "./check"

const StyledCheck = styled(Check)`
  margin-top: 4px;
  width: 20px;
  flex: 0 0 20px;
  @media (min-width: 768px) {
    margin-top: 8px;
  }
`

function Checked({ children, className }) {
  return (
    <div className="d-flex align-items-start">
      <StyledCheck className={`mr-1 ${className}`} />
      <span>{children}</span>
    </div>
  )
}

Checked.propTypes = {}

Checked.defaultProps = {}

export default Checked
