export const getHouseholdObjects = store => store.houseHold.objects
export const getForm = store => store.form
export const getCTA = store => store.cta

export const m3ByCategory = (store, category) => {
  const objects = getHouseholdObjects(store)
  if (!objects || objects.length === 0) {
    return 0
  }
  return objects.reduce((total, object) => {
    const t =
      object.categoryName === category
        ? total + object.count * object.m3
        : total
    return t
  }, 0)
}

export const m3 = store => {
  const objects = getHouseholdObjects(store)
  if (!objects || objects.length === 0) {
    return {}
  }
  return objects.reduce((total, object) => {
    const m3Object = object.count * object.m3
    const subTotal = total[object.categoryName]
      ? total[object.categoryName] + m3Object
      : m3Object
    const grandTotal = total.total ? total.total + m3Object : m3Object
    const t = {
      ...total,
      [object.categoryName]: subTotal,
      total: grandTotal,
    }
    return t
  }, {})
}

export const fetchInitialValues = store => {
  const form = getForm(store)
  return form.initialValues
}

export const ctaIsVisible = store => {
  const cta = getCTA(store)
  return cta.uuids.length !== 0
}
