import React from "react"
import Room from "./room"
import data from "../data/verhuizen-m3.json"

function HouseHoldForm({
  prefix,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) {
  return (
    <>
      <p className="mb-4">
        Vul zo accuraat mogelijk in welke spullen u heeft, zo kunnen wij een
        betere verhuis inschatting maken voor uw verhuizing.
      </p>
      <div role="tablist" className="" aria-multiselectable="true">
        {data.data.m3.map((category, index) => (
          <Room
            prefix={prefix}
            key={category.categoryName}
            id={category.categoryName}
            name={category.categoryTitle}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            values={values}
            objects={category.objects}
            open={index === 0}
          ></Room>
        ))}
      </div>
    </>
  )
}
HouseHoldForm.propTypes = {}

HouseHoldForm.defaultProps = {}

export default HouseHoldForm
